import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';
import { RED } from '../constants/colors';
import { WINDOW_MIN_WIDTH } from '../constants/layout';
import { INTERNAL_URLS } from '../constants/urls';
import { ZIndex } from '../constants/zIndex';
import { headerData } from '../data/header';
import { RobotoFont } from '../styles/common';
import { HeaderStyle } from '../styles/header';
import { getDesktopStyle, showOnDesktopStyle } from '../styles/layout';
import { pxToRem } from '../utils/common';
import { useGetHeaderStyle } from './providers/HeaderStyleProvider';

const DesktopHeader = () => {
	const { activeMenuType, headerStyleType } = useGetHeaderStyle();
	const headerStyle = useMemo(() => HeaderStyle[headerStyleType], [headerStyleType]);
	const { logo, textColor, subMenuTextColor, bgColor, subMenuBgColor } = headerStyle;

	const [isHover, setIsHover] = useState(false);

	const handleNavMouseOver = () => {
		if (!isHover) {
			setIsHover(true);
		}
	};

	const handleNavMouseOut = () => {
		if (isHover) {
			setIsHover(false);
		}
	};

	return (
		<HeaderWrap bgColor={bgColor}>
			<HeaderSection>
				<HeaderLogoWrapper>
					<Link to={INTERNAL_URLS.HOME}>
						<HeaderLogo src={logo} alt="Petdoc" />
					</Link>
				</HeaderLogoWrapper>
				<HeaderLogoArea />
				<HeaderNavWrapper>
					<HeaderNavArea />
					<HeaderNav isHover={isHover} onMouseEnter={handleNavMouseOver} onMouseLeave={handleNavMouseOut}>
						{headerData.map((data) => (
							<HeaderNavItems key={data.id}>
								{data.isOuter ? (
									<StyledALink href={data.link} target="_blank" rel="noopener noreferrer" color={textColor}>
										{data.text}
									</StyledALink>
								) : (
									<LinkWrapper isActive={activeMenuType === data.type} color={textColor}>
										<Link to={data.link}>{data.text}</Link>
									</LinkWrapper>
								)}
								{isHover && (
									<SubMenuList>
										{data.hasSubMenu &&
											data.subMenu &&
											data.subMenu.map((menu) => {
												return (
													<SubMenuItem key={menu.id} color={subMenuTextColor}>
														<Link to={menu.link}>{menu.text}</Link>
													</SubMenuItem>
												);
											})}
									</SubMenuList>
								)}
							</HeaderNavItems>
						))}
					</HeaderNav>
				</HeaderNavWrapper>
			</HeaderSection>
			{isHover && (
				<SubMenuWrap subMenuBgColor={subMenuBgColor}>
					<SubMenuSection />
				</SubMenuWrap>
			)}
		</HeaderWrap>
	);
};

export default DesktopHeader;

const HeaderNavArea = styled.div`
	width: ${pxToRem(580)};
	height: 100%;
`;

const HeaderWrap = styled.header(
	({ bgColor }: { bgColor: string }) => css`
		${showOnDesktopStyle};
		${getDesktopStyle(css`
			position: fixed;
			width: 100%;
			z-index: ${ZIndex.Header};
			//background-color: ${bgColor};
			background-color: rgba(255, 255, 255, 0.7);
			transition: background-color 0.3s;
			min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		`)}
	`,
);

const HeaderSection = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	height: ${pxToRem(103)};
	margin: 0 auto;
	align-items: center;

	${getDesktopStyle(css`
		width: ${pxToRem(1200)};
	`)}
`;

const HeaderLogoWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
`;

const HeaderLogoArea = styled.div`
	visibility: hidden;
	width: ${pxToRem(190)};
	height: auto;
`;

const HeaderLogo = styled.img`
	width: ${pxToRem(190)};
	height: auto;
`;

const HeaderNavWrapper = styled.nav`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const HeaderNav = styled.ul(
	({ isHover }: { isHover: boolean }) => css`
		position: absolute;
		top: ${isHover ? '150%' : '50%'};
		left: 0;
		// transform: translateY(${isHover ? '-38.7%' : '-50%'});
		transform: translateY(${isHover ? '-49%' : '-50%'});
		display: flex;
		justify-content: center;
		z-index: ${ZIndex.Header};

		& > li:first-of-type {
			width: ${pxToRem(180)};
		}
	`,
);

const HeaderNavItems = styled.li`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 ${pxToRem(50)};
`;

const LinkStyle = css`
	${RobotoFont};
	font-weight: 700;
	&:hover {
		color: ${RED};
	}
`;

const StyledALink = styled.a(
	({ color }: { color: string }) => css`
		${LinkStyle}
		color: ${color};
	`,
);

const LinkWrapper = styled.p(
	({ isActive, color }: { isActive?: boolean; color: string }) => css`
		a {
			${LinkStyle};
			color: ${isActive ? RED : color};
		}
	`,
);

const SubMenuWrap = styled.div(
	({ subMenuBgColor }: { subMenuBgColor: string }) => css`
		${showOnDesktopStyle};
		${getDesktopStyle(css`
			position: fixed;
			width: 100%;
			background-color: ${subMenuBgColor};
			transition: background-color 0.3s;
			min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
			box-shadow: ${pxToRem(4)} ${pxToRem(4)} ${pxToRem(5)} rgba(0, 0, 0, 0.1);
			border-top: 0.75px solid #C4C4C4;
		`)}
	`,
);

const SubMenuSection = styled.div`
	width: 100%;
	height: ${pxToRem(230)};
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(1200)};
	`)}
`;

const SubMenuList = styled.ul`
	padding: ${pxToRem(56)} 0 ${pxToRem(26)} 0;
	z-index: ${ZIndex.Header};
`;

const SubMenuItem = styled.li(
	({ color }: { color: string }) => css`
		text-align: center;
		z-index: ${ZIndex.SideBar};

		& > a {
			font-size: ${pxToRem(14)};
			font-weight: 400;
			line-height: ${pxToRem(32)};
			color: ${color};
			white-space: nowrap;
		}

		& > a:hover {
			font-weight: 600;
		}
	`,
);
